<template>
  <v-main
  >
    <div
      :class="[$vuetify.breakpoint.mdAndUp && 'fill-height']"
      class="d-block"
    >
      <page-app-bar />

      <router-view :key="$route.path" />

      <page-footer />
    </div>
  </v-main>
</template>

<script>
export default {
  name: 'PageView',

  components: {
    PageAppBar: () => import('./AppBar'),
    PageFooter: () => import('./Footer'),
  },
};
</script>
